import styled from "styled-components";

export const EventsHeroDiv = styled.div`
  --color: ${(props) => props.color || "var(--red)"};
  --backgroundColor: ${(props) =>
    props.isDark ? "var(--black)" : "var(--white)"};
  --accentColor: var(--red);
  --titleSize: 62px;
  width: var(--width);
  // height: 686px;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 var(--outerMargin) 0 var(--outerMargin);
  position: relative;
  margin-left: auto;
  margin-right: auto;
  background-color: var(--backgroundColor);
  color: var(--color);
  & .socialicons {
    position: absolute;
    top: 20px;
  }
  & h5 {
    position: relative;
    top: 0px;
  }
  & h5 + h5 {
    top: 7px;
  }
  & h2 {
    max-width: 1000px;
    text-align: center;
    font-size: var(--titleSize);
    & + h2 {
      margin-top: 0;
    }
  }
  & > div{
    &.mobile-banner{
      display:none;
      @media (max-width:767px){
        display:block;
        .gatsby-image-wrapper{
          width:100vw!important;
        }
      }
    }
  }
  & > div:first-of-type {
    width: 1240px;
    width: auto;
    height: auto;
    top: 0px;
    z-index: 2;
    position: relative;
    overflow: visible;
    margin-top:30px;
    margin-bottom:30px;
    &.titleImage:after {
      content: "";
      top: 0;
      bottom: 50%;
      left: 0;
      right: 0;
      position: relative;
    }
    & > img {
      width: 100%;
      object-fit: cover;
    }
    & > .gatsby-image-wrapper {
      width: 100% !important;
      height: 100% !important;
    }
    & + div {
      display: flex;
      align-items: center;
      flex-direction: column;
      position: relative;
      width: calc(calc(var(--width) - var(--outerMargin)) - var(--outerMargin));
      /* width: 100%; */
      height: auto;
      top: 0px;
      /* margin-left: -2000px;
      margin-right: -2000px; */
      z-index: 3;
      /* background-image: ${(props) =>
        props.isDark
          ? "linear-gradient(var(--black), rgba(16, 24, 32, 0))"
          : "linear-gradient(var(--white), rgba(255, 255, 255, 0))"}; */
      &.desktop-banner{
        @media(max-width: 767px)
        {
          display:none;
        }
      }
    }
    
  }
  @media (max-width: 767px) {
    --outerMargin: 0px;
    --titleSize: 48px;
  }
`;

export const CallToActionBox = styled.div`
  --color: ${(props) => props.color || "var(--white)"};
  display: inline-block;
  border: 1px solid var(--color);
  height: 56px;
  width: 400px;
  bottom: 63px;
  z-index: 3;
  position: absolute;
  background-color: rgba(16, 24, 32, 0.25);
  & a {
    color: var(--color);
    display: inline-flex;
    justify-content: center;
    align-items: center;
    padding: 14px 24px;
    width: 100%;
    height: 100%;
    user-select: none;
  }
`;
