import React from "react";
import { graphql } from "gatsby";
import Img from "gatsby-image";
import Layout from "./../components/Layout";
import ArticleBody from "./../components/ArticleBody";
import ArticleFilter from "./../components/ArticleFilter";
import EventHero from "./../components/EventHero";
import Selector from "./../components/Selector";
import { getDate } from "./../modules/time";
import { SmallButton } from "./../components/Buttons";
import Download from "./../components/icons/Download";

/**
 * This is a generic page template.
 *
 * TODO: a wrapper for the content.
 *
 */

const editorialTypes = {
  // { id: "art", name: "Art" },
  // { id: "film", name: "Film" },
  // { id: "design", name: "Design" },
  // { id: "music", name: "Music" },
  // { id: "literature", name: "Literature" },
  culture: "Culture",
  style: "Style",
  expression: "Expression",
  opinion: "Opinion",
};

const ArticleWordsPageTemplate = ({ data, pageContext }) => {
  // console.log(data, pageContext);
  const {
    title,
    headerImage,
    headerImageMobile,
    richContent,
    editorialType,
    editorialAuthor,
    editorialDarkMode,
    editorialAccentColor,
    editorialShortDescription,
    editorialDate,
    venuePdfForDownload,
  } = data.craftEntryInterface;
  // console.log(headerImage[0].SEOImage.childImageSharp.fixed.src);
  const myColor = editorialAccentColor || "var(--green)";
  const textColor = editorialDarkMode ? "var(--white)" : "var(--black)";
  const backgroundColor = editorialDarkMode ? "var(--black)" : "var(--white)";
  // const [currentTab, setCurrentTab] = React.useState(0);
  const [tabContent, setTabContent] = React.useState([]);
  const useTabs =
    richContent.filter(
      (x) => x.__typename === "Craft_richContent_tabSectionHeader_BlockType"
    ).length > 0;
  React.useEffect(() => {
    if (useTabs && !tabContent.length) {
      // console.log("parsing tabs!");
      let tabData = [];
      for (let i = 0; i < richContent.length; i++) {
        if (richContent[i].typeHandle === "tabSectionHeader") {
          const myTabHeader = richContent[i].tabSectionHeader;
          const myTabSlug = richContent[i].tabSlug || "";
          let start = i + 1;
          let end = start;
          while (
            richContent[end] &&
            richContent[end].typeHandle !== "tabSectionHeader"
          ) {
            end++;
          }
          const myTabContent = richContent.slice(start, end);
          tabData.push({
            title: myTabHeader,
            slug: myTabSlug,
            content: (
              <ArticleBody
                mainColumn={myTabContent}
                color={myColor}
                textColor={textColor}
                // hideSocial
              />
            ),
          });
        }
      }
      setTabContent(tabData);
    }
  }, [useTabs, tabContent.length, richContent, myColor, textColor]);

  return (
    <Layout
      footerColor={myColor}
      backgroundColor={backgroundColor}
      SEOIsArticle
      title={title}
      SEOImageURL={
        headerImage &&
        headerImage.length &&
        headerImage[0].SEOImage &&
        headerImage[0].SEOImage.childImageSharp
          ? `https://alserkal.online${headerImage[0].SEOImage.childImageSharp.fixed.src}`
          : null
      }
      SEODescription={editorialShortDescription}
      // headerColor={textColor}
    >
      <EventHero
        topline={editorialTypes[editorialType[0]]}
        secondline={getDate(editorialDate)}
        titleText={title}
        subTitleText={editorialAuthor}
        titleImage={
          headerImage && headerImage.length && headerImage[0].wide ? (
            headerImage[0].wide.childImageSharp ? (
              <Img
                fixed={headerImage[0].wide.childImageSharp.fixed}
                alt={title}
              />
            ) : (
              <img src={headerImage[0].wide.publicURL} alt={title} />
            )
          ) : null
        }
        titleMobileImage={
          headerImageMobile && headerImageMobile.length && headerImageMobile[0].tallImage ? (
            headerImageMobile[0].tallImage.childImageSharp ? (
              <Img
                fixed={headerImageMobile[0].tallImage.childImageSharp.fixed}
                alt={title}
              />
            ) : (
              <img src={headerImageMobile[0].tallImage.publicURL} alt={title} />
            )
          ) : null
        }
        color={myColor}
        isDark={editorialDarkMode}
      />
      <div>
        {useTabs ? (
          tabContent.length ? (
            <Selector tabs={tabContent} color={"var(--purple)"}  leftColumn={
              venuePdfForDownload.length && venuePdfForDownload[0].url ? (
                <a href={venuePdfForDownload[0].url} download>
                  <SmallButton>
                    <Download color={"var(--color)"} />
                    Download PDF pack
                  </SmallButton>
                </a>
              ) : null
            } />
          ) : null
        ) : (
          <ArticleBody
            mainColumn={richContent}
            color={myColor}
            textColor={textColor}
            leftColumn={
              venuePdfForDownload.length && venuePdfForDownload[0].url ? (
                <a href={venuePdfForDownload[0].url} download>
                  <SmallButton>
                    <Download color={"var(--color)"} />
                    Download PDF pack
                  </SmallButton>
                </a>
              ) : null
            }
          />
        )}
        <ArticleFilter backgroundColor={"var(--black)"} defaultType={"words"} />
      </div>
    </Layout>
  );
};

export default ArticleWordsPageTemplate;

export const pageQuery = graphql`
  query ($id: String!) {
    craftEntryInterface(id: { eq: $id }) {
      ... on Craft_articles_words_Entry {
        title
        headerImage {
          ... on Craft_editorialAssets_Asset {
            SEOImage: localFile {
              childImageSharp {
                fixed(width: 1200) {
                  src
                }
              }
            }
            wide: localFile {
              publicURL
              childImageSharp {
                fixed(width: 1440, height: 750, fit: COVER) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        headerImageMobile{ 
          ... on Craft_editorialAssets_Asset {
            tallImage: localFile {
              publicURL
              childImageSharp {
                fixed(height: 550) {
                  src
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        editorialDarkMode
        editorialType
        editorialAuthor
        editorialAccentColor
        editorialShortDescription
        editorialDate
        venuePdfForDownload {
          url
        }
        richContent {
          __typename
          ... on Craft_richContent_tabSectionHeader_BlockType {
            tabSectionHeader
            tabSlug
            typeHandle
          }
          ... on Craft_richContent_matterportEmbed_BlockType {
            typeHandle
            matterportUrl
          }
          ... on Craft_richContent_podiantPlayer_BlockType {
            podiantUrl
            typeHandle
          }
          ... on Craft_richContent_anghamiPlayer_BlockType {
            anghamiUrl
            typeHandle
          }
          ... on Craft_richContent_soundcloudPlayer_BlockType {
            soundcloudUrl
            typeHandle
          }
          ... on Craft_richContent_spotifyPlayer_BlockType {
            typeHandle
            spotifyUrl
          }
          ... on Craft_richContent_image_BlockType {
            id
            typeHandle
            imageWithCaptionImage {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(width: 715, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            imageWithCaptionCaption
          }
          ... on Craft_richContent_slideShow_BlockType {
            id
            typeHandle
            images {
              ... on Craft_editorialVideo_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            carouselCaption
          }
          ... on Craft_richContent_richText_BlockType {
            id
            typeHandle
            richText
          }
          ... on Craft_richContent_imageSlider_BlockType {
            typeHandle
            imageSliderImages {
              ... on Craft_editorialVideo_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            imageSliderCaptions {
              captionLine4
              captionLine3
              captionLine2
              captionLine1
            }
          }
          ... on Craft_richContent_videoWithCaption_BlockType {
            typeHandle
            videoWithCaptionCaption
            videoWithCaptionVideoOnTheLeft
            videoWithCaptionUseCredit
            videoWithCaptionUrl
            videoWithCaptionVideo {
              url
            }
          }
          ... on Craft_richContent_photoWithQuote_BlockType {
            typeHandle
            photoWithQuoteImage {
              ... on Craft_communityAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_eventsAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
              ... on Craft_editorialAssets_Asset {
                slide: localFile {
                  publicURL
                  childImageSharp {
                    fixed(height: 460, fit: COVER) {
                      src
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
              }
            }
            photoWithQuoteImageOnTheLeft
            photoWithQuoteImageQuote
            photoWithQuoteImageQuoteAttribution
          }
        }
      }
    }
  }
`;
