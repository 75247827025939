import React,{useEffect} from "react";
import PropTypes from "prop-types";
// import Img from "gatsby-image";
// import MiscSocialSmall from "./../icons/MiscSocialSmall";
import { H5SmallMedium, H2BigStrong, H1BigStrong, H2Big, H4Italic } from "./../Typography";
import { EventsHeroDiv, CallToActionBox } from "./elements";
import { OutboundLink } from 'gatsby-plugin-gtag';

const EventsHero = ({
  topline,
  secondline,
  titleImage,
  titleMobileImage,
  titleText,
  subTitleText,
  isDark,
  callToAction,
  linkUrl,
  color,
}) => {
  const c=(color!=null?color:"var(--black)");
  const dc=(titleMobileImage!=null?"desktop-banner":"");
  const mc=(titleMobileImage!=null?"mobile-banner":"");

  useEffect(() => {
    //const onLoad = (event) => {
      if(callToAction && linkUrl)
      {
        let action=callToAction.toLowerCase();
        action=action.replace(" ", "_");
        
        document.querySelector('.gtag-trk[href="'+linkUrl+'"]').addEventListener('click', function() {
            gtag('event', action);
           
        });
      }
      
    //};
      
    //window.addEventListener('load', onLoad);
    
    
  }, []);

  return (
    <EventsHeroDiv isDark={isDark} color={color || "var(--red)"}>
      {/*<MiscSocialSmall color={color || "var(--red)"} />*/}
      <H5SmallMedium style={{ color: "var(--purple)" }}>
        {topline}
      </H5SmallMedium>
      <H5SmallMedium
        style={{ color: isDark ? "var(--white)" : "var(--black)" }}
      >
        {secondline}
      </H5SmallMedium>
      <div>
        <H1BigStrong>{titleText}</H1BigStrong>
        <H2Big>{subTitleText}</H2Big>
      </div>
      <div className={`titleImage ${dc}`}>{titleImage}</div>
      {titleMobileImage ?(
        <div className={`titleImageMobile ${mc}`}>{titleMobileImage}</div>
      ) : null}
      {callToAction && linkUrl ? (
        <CallToActionBox>
          <H4Italic>
            <OutboundLink href={linkUrl} className="gtag-trk">{callToAction || "Book Your Tickets"}</OutboundLink>
          </H4Italic>
        </CallToActionBox>
      ) : null}
    </EventsHeroDiv>
  );
};

export default EventsHero;

EventsHero.propTypes = {
  topline: PropTypes.string,
  titleImage: PropTypes.element,
  titleText: PropTypes.string,
  subTitleText: PropTypes.string,
  callToAction: PropTypes.string,
  isDark: PropTypes.bool,
  linkUrl: PropTypes.string,
};

EventsHero.defaultProps = {
  isDark: false,
};
